<template>
  <div class="single-location-content">
    <h3 class="location-title orange-bar">{{ singleLocation.title }}</h3>    
      <div class="location-meta" v-html="singleLocation.content">      
    </div>
  </div>
</template>
<script>
export default {
  props: ["singleLocation"]
};
</script>