<template>
  <div class="get-a-quote">
    <FixedNavbar />
    <div class="su_page_content">
      <TitleBanner v-bind:PageTitle="BannerTitle" />
      <QuoteForm />
      <Locations />
      <!-- <Resources /> -->
    </div>
    <Footer />
  </div>
</template>

<script>
import FixedNavbar from "@/components/Common/Navbars/FixedNavbar.vue";
import TitleBanner from "@/components/Common/TitleBanner.vue";
import QuoteForm from "@/components/GetAQuote/QuoteForm.vue";
import Locations from "@/components/GetAQuote/Locations/Locations.vue";
// import Resources from "@/components/Common/Resources/Resources.vue";
import Footer from "@/components/Common/Footer.vue";
import axios from "axios";
export default {
  name: "Get-a-quote",
  components: {
    FixedNavbar,
    TitleBanner,
    QuoteForm,
    Locations,
    // Resources,
    Footer
  },
  data() {
    return {
      BannerTitle: []
    };
  },
  mounted() {
    this.getBanner();
    this.setMetaInfo(301)
  },
  methods: {
    getBanner() {
      this.loader = this.showSpinner();
      axios
        .post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
          params: {
            post_type: "header_banner",
            taxonomy: "banner_type",
            cat_name: "get-quote-page-banner"
          }
        })
        .then(response => {
          this.loader.hide();
          this.BannerTitle = response.data.customPostList;
          console.log("BannerTitle==", this.BannerTitle);
        })
        .catch(error => {
          console.log("error", error);
        });
    }
  }
};
</script>



