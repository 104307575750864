<template>
  <div class="quoteFormWrapper spacer-80">
    <div class="container">
      <div class="gzvid-mkto-form">
        <form v-if="!isFormSubmitted" id="mktoForm_5833"></form>
        <div v-if="isFormSubmitted" class="form_subscribe_thanks color-white">
          <unicon name="uniCheckCircle" fill="deeppink" />Thank you for submitting your request.
          <span
            class="d-block"
          >Our experts will contact you soon.</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { invalidDomains } from "./../../variables/commonVar";

export default {
  data() {
    return {
      isFormSubmitted: false
    };
  },
  methods: {},
  mounted() {
    window.MktoForms2.loadForm(
      "//app-lon03.marketo.com",
      "690-NGB-767",
      5833,
      form => {
        form.onSuccess(() => {
          this.isFormSubmitted = true;
          return false;
        });
      }
    );
    this.marketoReady(invalidDomains);
  }
};
</script>