<template>
  <section class="locations">
    <div class="single-location-container">
      <div class="single-location spacer-80" v-for="singleLocation in locations"
      v-bind:key="singleLocation.id" v-bind:style="{ 'background-image': 'url('+singleLocation.fullImage+')' }">
        <singleLocation v-bind:singleLocation="singleLocation" />
      </div>
    </div>
  </section>
</template>
<script>
import singleLocation from "@/components/GetAQuote/Locations/singleLocation.vue";
import axios from "axios";
export default {
  name: "Locations",
  components: {
    singleLocation
  },
  data() {
    return {
      locations: [ 
      ]
    };
  },
  mounted() {
    this.loader = this.showSpinner();
    axios.post(`${process.env.VUE_APP_API}/wp/v1/custom_post_type`, {
        params: {
          post_type: "address_list"
        }
      })
      .then(response => { this.loader.hide();
        this.locations = response.data.customPostList;
        console.log("locations==", this.locations);
      })
      .catch(error => {
        console.log("error", error);
      });
  }
};
</script>